:root {
  --box-shadow-color: #c9c9c9;
}

.sticky-container {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
}

@media only screen and (width >= 689px) {
  .sticky-container {
    width: 40%;
  }

  .track_comp_info {
    max-width: 80%;
  }
}

@media only screen and (width <= 689px) {
  .track_comp_info {
    width: 200px;
    max-width: 200px;
  }
}

.audio-player {
  box-shadow: .2rem .2rem 1rem .2rem var(--box-shadow-color);
  background-color: #fff;
  width: 100%;
}

.player-body {
  width: 100%;
  padding: 1rem 1rem .5rem;
}

span[aria-expanded="true"] .fa-chevron-up, span[aria-expanded="false"] .fa-chevron-down {
  display: none;
}

#playfloatingbutton {
  width: 15px;
}

.chevron-collapse {
  padding: 5px;
}

.player-header {
  margin-bottom: 0;
}

@keyframes beat {
  0%, 50%, 100% {
    transform: scale(1);
  }

  30%, 80% {
    transform: scale(.92, .95);
  }
}

.fa-beat {
  animation: 2s linear infinite beat;
}

.isDisabled {
  pointer-events: none;
}
/*# sourceMappingURL=index.878f9f23.css.map */
